import React from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom'
import './App2.css';
import Root from "./components/Root";
import BlogFree from "./components/BlogFree";
import BlogRefresh from "./components/BlogRefresh";
import AboutUs from "./components/AboutUs";
import Blog from "./components/Blog";
import BlogCost from "./components/BlogCost";
import BlogLandingVsWebpage from "./components/BlogLandingVsWebpage";
import BlogSeoWitch from "./components/BlogSeoWitch";
import LandingSeoAudit from "./components/LandingSeoAudit";
import LandingSeoAuditThanks from "./components/LandingSeoAuditThanks";
import CookieBanner from "./components/CookieBanner";
import BlogGoogleMisconception from "./components/BlogGoogleMisconception";
import Appointment from "./components/Appointment";
import BlogMobileFriendly from "./components/BlogMobileFriendly";
import BlogSantaSeo from "./components/BlogSantaSeo";
import AppointmentThanks from "./components/AppointmentThanks";
import LandingCouponA from "./components/LandingCouponA";
import LandingCouponThanks from "./components/LandingCouponThanks";
import BlogQuickWebpageTest from "./components/BlogQuickWebpageTest";
import Blog2025WebpageTrends1 from "./components/Blog2025WebpageTrends1";
import Blog2025WebpageTrends2 from "./components/Blog2025WebpageTrends2";

function App() {

    return (
        <>
            {/*<CookieBanner/>*/}
            <Router>
                <Routes>
                    <Route path="/" element=<Root/> />
                    <Route path="/blog.html" element=<Blog/> />
                    <Route path="/rolunk.html" element=<AboutUs/> />
                    <Route path="/idopont-foglalas.html" element=<Appointment/> />
                    <Route path="/idopont-foglalas-thanks.html" element=<AppointmentThanks/> />

                    <Route path="/blog/ingyenes-weblap.html" element=<BlogFree/> />
                    <Route path="/blog/weblap-frissites.html" element=<BlogRefresh/> />
                    <Route path="/blog/mennyibe-kerul-egy-weblap-keszitese.html" element=<BlogCost/> />
                    <Route path="/blog/landing-vagy-weboldal.html" element=<BlogLandingVsWebpage/> />
                    <Route path="/blog/seo-roviden-erthetoen.html" element=<BlogSeoWitch/> />
                    <Route path="/blog/google-tevhitek.html" element=<BlogGoogleMisconception/> />
                    <Route path="/blog/mobil-barat.html" element=<BlogMobileFriendly/> />
                    <Route path="/blog/mikulas-seo-titok.html" element=<BlogSantaSeo/> />
                    <Route path="/blog/weboldal-gyorsteszt.html" element=<BlogQuickWebpageTest/> />
                    <Route path="/blog/2025-webdesign-trendjei-1.html" element=<Blog2025WebpageTrends1/> />
                    <Route path="/blog/2025-webdesign-trendjei-2.html" element=<Blog2025WebpageTrends2/> />

                    <Route path="/lp/seo-audit.html" element=<LandingSeoAudit/> />
                    <Route path="/lp/seo-audit-thanks.html" element=<LandingSeoAuditThanks/> />
                    <Route path="/lp/kupon-a.html" element=<LandingCouponA/> />
                    <Route path="/lp/kupon-thanks.html" element=<LandingCouponThanks/> />

                    <Route path="/ingyenes_weblap.html" element={<Navigate to="/blog/ingyenes-weblap.html" replace />} />
                    <Route path="/weblap_frissites.html" element={<Navigate to="/blog/weblap-frissites.html" replace />} />
                    <Route path="/mennyibe_kerul_egy_weblap_keszitese.html" element={<Navigate to="/blog/mennyibe-kerul-egy-weblap-keszitese.html" replace />} />
                    <Route path="/landing_vagy_weboldal.html" element={<Navigate to="/blog/landing-vagy-weboldal.html" replace />} />
                    <Route path="/seo-roviden-erthetoen.html" element={<Navigate to="/blog/seo-roviden-erthetoen.html" replace />} />
                    <Route path="/google-tevhitek.html" element={<Navigate to="/blog/google-tevhitek.html" replace />} />
                    <Route path="/mobil-barat.html" element={<Navigate to="/blog/mobil-barat.html" replace />} />
                    <Route path="/mikulas-seo-titok.html" element={<Navigate to="/blog/mikulas-seo-titok.html" replace />} />
                    <Route path="/weboldal-gyorsteszt.html" element={<Navigate to="/blog/weboldal-gyorsteszt.html" replace />} />
                    <Route path="/landing-seo-audit.html" element={<Navigate to="/lp/seo-audit.html" replace />} />
                    <Route path="/landing-seo-audit-thanks.html" element={<Navigate to="/lp/seo-audit-thanks.html" replace />} />
                    <Route path="/landing-kupon-a.html" element={<Navigate to="/lp/kupon-a.html" replace />} />
                    <Route path="/landing-kupon-thanks.html" element={<Navigate to="/lp/kupon-thanks.html" replace />} />
                </Routes>
            </Router>
        </>
  );
}

export default App;
